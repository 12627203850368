import "./src/css/reset.scss";
import "./src/css/main.scss";
import "./src/css/text.scss";

export const onRouteUpdate = ({ location }) => {
  const { pathname, search } = location;

  // パラメータを削除する条件
  if ((pathname === "/blog/" && (search.includes("p=") || search.includes("paged=") || search.includes("m=") || search.includes("cat="))) || (pathname === "/category/hikkoshi/" && (search.includes("cat=5") || search.includes("paged="))) || search.includes("p=") || (pathname === "/smartphone/news_post.php" && search.includes("id=")) || (pathname === "/" && search.includes("id="))) {
    const newUrl = pathname === "/smartphone/news_post.php" ? "/" : pathname;
    window.history.replaceState({}, "", newUrl);
  }
};
